import { DateTime } from 'luxon';
// Package Dependencies
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

// Action Dependencies
import {
  RETRIEVE_APPOINTMENTS_REQUEST,
  retrieveAppointmentsError,
  retrieveAppointmentsResponse,
} from '../../action/Appointments/Appointments';
import {
  transitionAppointmentRequest,
} from '../../action/Appointments/Transition';
// Additional Dependencies
import axios from '../../utility/Axios';

// Optimized Function to Get URI Path Parameter
function getURIPathParam(id, type) {
  const err = console.error;
  const uuidv4Regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  const segment = (window.location.pathname.split("/")[id] || "").split("#")[0];
  return (typeof id !== "number" || id < 1 || (type && typeof type !== "string")) ? (err("Invalid Parameter"), null)
    : !segment ? (err("Path Segment Does Not Exist"), null)
    : (type === "uuidv4" && !uuidv4Regex.test(segment)) ? (err("Invalid UUIDv4 Format"), null)
    : segment;
};

// Function Handle Appointments
function* handleAppointments(action) {
  try {
    // Define Query Parameters
    const state = "scheduled";
    const location_uuid = getURIPathParam(1, "uuidv4");
    const date = DateTime.now()
      .setZone("UTC")
      .toFormat("yyyy-MM-dd")
      .toLocaleString(DateTime.DATE_SHORT);
    const sort_column = "date_time";
    const sort_direction = "asc";
    const limit = 1;
    // Retrieve Appointments Request
    const response = yield call(
      axios.get_request,
      `/appointment?state=${state}&location_uuid=${location_uuid}&date=${date}&mobile=1${action.payload.mobile}&sort_column=${sort_column}&sort_direction=${sort_direction}&limit=${limit}`
    );
    // Retrieve Appointments Response
    yield put(retrieveAppointmentsResponse({ data: response.data.data[0] }));
    yield put(transitionAppointmentRequest());
  } catch (error) {
    // Retrieve Appointments Error
    yield put(retrieveAppointmentsError(error));
  }
}

// Export
export const AppointmentsSaga = [takeLatest(RETRIEVE_APPOINTMENTS_REQUEST, handleAppointments)];
