import React from "react";
import { connect } from "react-redux";
import { DateTime } from "luxon"

class Confirmed extends React.Component {

  render() {
    const { data, busy, appointment } = this.props;

    if (!appointment.busy && appointment.response) {
      return (
        <div className="mt-28 m-4 row content flex-auto">
          <div className="flex flex-col m-auto">
            <div className="mx-auto">
              <i className="fas fa-check-circle fa-5x text-green-500"></i>
            </div>
            <div className="mx-auto my-8">
              <div className="text-5xl font-medium">Thanks, {appointment.values.firstName}!</div>
            </div>
            <div className="mx-auto">
              <div className="text-xl font-light">Your appointment has been scheduled.</div>
            </div>
          </div>
        </div>
      )
    }

    if (!busy && !data) {
      return (
        <div className="mt-28 m-4 row content flex-auto">
          <div className="flex flex-col m-auto">
            <div className="mx-auto">
              <i className="fas fa-times-circle fa-5x text-red-500"></i>
            </div>
            <div className="mx-auto my-8">
              <div className="text-5xl font-medium">Sorry!</div>
            </div>
            <div className="mx-auto">
              <div className="text-xl font-light">Your appointment could not be found.</div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="mt-28 m-4 row content flex-auto">
        <div className="flex flex-col m-auto">
          <div className="mx-auto">
            <i className="fas fa-check-circle fa-5x text-green-500"></i>
          </div>
          <div className="mx-auto my-8">
            <div className="text-5xl font-medium">Thanks, {data.contact.name.first}!</div>
          </div>
          <div className="mx-auto">
            <div className="text-xl font-light">Your appointment is confirmed for</div>
          </div>
          <div className="mx-auto">
            <div className="text-3xl">{DateTime.fromISO(data.appointment.date_time).setZone("UTC").toLocaleString(DateTime.TIME_SIMPLE)} Today.</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    appointment: state.createAppointmentReducer,
    data: state.appointmentsReducer.data,
    busy: state.appointmentsReducer.busy,
  }
}

export default connect(mapStateToProps, null)(Confirmed)
