import React, { useState } from 'react';

import Button from '@alliancesafetycouncil/asc-button';

import Header from '../../components/Header/Header';
import KeyPad from '../../components/KeyPad/KeyPad';
import useIdleTimeout from '../../utility/IdleTimeout';

export const CheckIn = (props) => {
  const [error, setError] = useState(false);

  const validate = () => {
    let valid = true;

    if (props.value.length < 10) {
      setError(true);
      valid = false;
    }

    return valid;
  };

  const handleContinue = () => {
    const valid = validate();

    if (valid) {
      props.history.push("/confirmation");
    }
  };

  // Optimized Function to Get URI Path Parameter
  function getURIPathParam(id, type) {
    const err = console.error;
    const uuidv4Regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    const segment = (window.location.pathname.split("/")[id] || "").split("#")[0];
    return (typeof id !== "number" || id < 1 || (type && typeof type !== "string")) ? (err("Invalid Parameter"), null)
      : !segment ? (err("Path Segment Does Not Exist"), null)
      : (type === "uuidv4" && !uuidv4Regex.test(segment)) ? (err("Invalid UUIDv4 Format"), null)
      : segment;
  };

  useIdleTimeout(() => (window.location.href = `/${getURIPathParam(1, "uuidv4")}`), 1000 * 30);

  return (
    <div className="relative flex flex-col h-screen bg-blue-900">
      <div className="absolute bottom-8" style={{ left: "100px" }}>
        <Button type="secondary" size="md" onClick={() => props.history.push("/")}>
          <span className="mx-3">Back</span>
        </Button>
      </div>
      {/* Main Header wrapper */}
      <Header />
      {/* Main Content Area */}
      <div className="flex items-center justify-center flex-1">
        <div className="w-full flex-column sm:w-80">
          <div className="grid grid-cols-1 gap-6">
            <div>
              <div className="mb-8 text-3xl text-center text-white">Confirm Appointment</div>

              {/* KeyPad */}

              <div>
                <KeyPad
                  value={props.value}
                  setValue={(value) => {
                    setError(false);
                    props.setValue(value);
                  }}
                  error={error}
                />
              </div>
              <div className="flex justify-center mt-8">
                <Button type="save" size="md" onClick={handleContinue}>
                  <span className="mx-16">Continue</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckIn;
