// Package Dependencies
import { combineReducers } from 'redux'
// Additional Dependencies
import AppointmentReducer from './Appointments/Transition'
import AppointmentsReducer from './Appointments/Appointments'
import CreateAppointmentReducer from './Appointments/Create'
// Combine Reducers
const RootReducer = combineReducers({
  appointmentReducer: AppointmentReducer,
  appointmentsReducer: AppointmentsReducer,
  createAppointmentReducer: CreateAppointmentReducer
})
// Export
export default RootReducer
