import React, { useState } from 'react';

import {
  HashRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Appointment from './views/Appointment';
import DateTime from './views/Appointment/DateTime';
import CheckIn from './views/CheckIn';
import Confirmation from './views/Confirmation';
import Decision from './views/Decision';

function App() {
  const [value, setValue] = useState("")

  return (
    <div>
      <Router>
          <Switch>
            <Route exact path="/" name="Decision" component={Decision} />
            <Route exact path="/appointment" name="Appointment" component={Appointment} />
            <Route exact path="/date-time" name="DateTime" component={DateTime} />
            <Route exact path="/check-in" name="CheckIn" render={(props) => <CheckIn {...props} value={value} setValue={setValue} /> } />
            <Route exact path="/confirmation" name="Confirmation" render={(props) => <Confirmation {...props} value={value} />} />
          </Switch>
      </Router>
    </div>
  );
}

export default App;
