// Transition Appointment Request
export const TRANSITION_APPOINTMENT_REQUEST = 'TRANSITION_APPOINTMENT_REQUEST'
export function transitionAppointmentRequest(data) {
  return { type: TRANSITION_APPOINTMENT_REQUEST, data }
}

// Transition Appointment Response
export const TRANSITION_APPOINTMENT_RESPONSE = 'TRANSITION_APPOINTMENT_RESPONSE'
export function transitionAppointmentResponse(payload) {
  return { 
    type: TRANSITION_APPOINTMENT_RESPONSE, payload
  }
}

// Transition Appointment Error
export const TRANSITION_APPOINTMENT_ERROR = 'TRANSITION_APPOINTMENT_ERROR'
export function transitionAppointmentError(data) {
  return { type: TRANSITION_APPOINTMENT_ERROR, data }
}