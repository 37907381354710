import React from "react";

const Time = ({ time, disabled, selected, onClick }) => {

  if (disabled) {
    return (
      <div onClick={onClick} style={{ cursor: 'pointer' }}>
        <div className="flex h-12 bg-gray-300 rounded-lg text-lg text-gray-500 justify-center items-center shadow-lg">
          <div>{time}</div>
        </div>
      </div>
    )
  }


  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      {selected !== true ?
        <div className="flex h-12 bg-white rounded-lg text-lg text-gray-500 justify-center items-center shadow-lg border border-gray-300">
          <div style={{ color: '#000000' }}>{time}</div>
        </div>

        : (

          <div className="flex h-12 bg-green-500 rounded-lg text-lg text-white justify-center items-center shadow-lg">
            <div>{time}</div>
          </div>
        )}
    </div >
  );
};

export default Time;
