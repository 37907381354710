import React from "react"
import NumberFormat from 'react-number-format'

export const Input = ({ label, type, name, id, subLabel, bottomLabel, onChange, error }) => {
  return (
    <div>
      <label
        htmlFor="first-name"
        className={`block font-medium ${!error && 'text-gray-700'}`}
        style={error ? { color: '#D22B2B' } : {}}
      >
        {label}
      </label>
      <div className="text-sm text-gray-500">{subLabel}</div>
      <input
        onChange={onChange}
        type={type}
        name={name}
        id={id}
        className="mt-1 focus:ring-transparent focus:border-green-300 block w-full border border-gray-300 h-12 rounded-md flex items-center pl-3"
      />
      <div className="text-sm text-gray-500 mt-1">{bottomLabel}</div>
    </div>
  );
};

export const PhoneInput = ({ label, type, name, id, subLabel, bottomLabel, onChange, error }) => {
  return (
    <div>
      <label
        htmlFor="first-name"
        className={`block font-medium ${!error && 'text-gray-700'}`}
        style={error ? { color: '#D22B2B' } : {}}
      >
        {label}
      </label>
      <div className="text-sm text-gray-500">{subLabel}</div>
      <NumberFormat
        onChange={onChange}
        name={name}
        type={type}
        id={id}
        className="mt-1 focus:ring-transparent focus:border-green-300 block w-full border border-gray-300 h-12 rounded-md flex items-center pl-3"
        format="(###) ###-####"
        placeholder="(xxx) xxx-xxxx"
      />

      <div className="text-sm text-gray-500 mt-1">{bottomLabel}</div>
    </div>
  );
};


export const RadioButtons = ({ label, name, id, type }) => {
  return (
    <div className="flex flex-row items-center">
      <input
        id={id}
        name={name}
        type={type}
        className="focus:ring-transparent h-4 w-4 text-indigo-600 border-gray-300"
      />
      <label
        htmlFor="push-everything"
        className="ml-2 block"
      >
        {label}
      </label>
    </div>
  );
};

export const CheckBoxes = ({ label, name, id, type, value, onChange }) => {
  return (
    <div className="flex flex-row items-center">
      <input
        onChange={onChange}
        id={id}
        name={name}
        type={type}
        value={value}
        className="focus:ring-transparent h-6 w-6 text-indigo-600 border-gray-300"
      />
      <label
        htmlFor="push-everything"
        className="ml-2 block text-sm"
      >
        {label}
      </label>
    </div>
  );
};

export const InputDollars = ({ type, label, name, id, subLabel }) => {
  return (
    <div className="relative">
      <label
        htmlFor="first-name"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="text-sm text-gray-500">{subLabel}</div>
      <i class="fal fa-dollar-sign text-gray-500 absolute top-9 left-3"></i>
      <input
        type={type}
        name={name}
        id={id}
        className="pl-6 mt-1 focus:ring-transparent focus:border-green-300 block w-full border-gray-300 rounded-md"
      />
    </div>
  );
};
