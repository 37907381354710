// Retrieve Appointments Request
export const RETRIEVE_APPOINTMENTS_REQUEST = 'RETRIEVE_APPOINTMENTS_REQUEST'
export function retrieveAppointmentsRequest(payload) {
  return { type: RETRIEVE_APPOINTMENTS_REQUEST, payload }
}

// Retrieve Appointments Response
export const RETRIEVE_APPOINTMENTS_RESPONSE = 'RETRIEVE_APPOINTMENTS_RESPONSE'
export function retrieveAppointmentsResponse(payload) {
  return { type: RETRIEVE_APPOINTMENTS_RESPONSE, payload }
}

// Retrieve Appointments Error
export const RETRIEVE_APPOINTMENTS_ERROR = 'RETRIEVE_APPOINTMENTS_ERROR'
export function retrieveAppointmentsError(data) {
  return { type: RETRIEVE_APPOINTMENTS_ERROR, data }
}