// Package Dependencies
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

// Action Dependencies
import {
  TRANSITION_APPOINTMENT_REQUEST,
  transitionAppointmentError,
  transitionAppointmentResponse,
} from '../../action/Appointments/Transition';
// Additional Dependencies
import axios from '../../utility/Axios';

// Function Handle Transition Appointment
function* handleTransitionAppointment(action) {
  try {
    const data = yield select((state) => state.appointmentsReducer.data)
    const state = 'queued'
    // Transition Appointment Request
    const response = yield call(axios.patch_request, `/appointment/${data.appointment.uuid}/transition`, {
      "version": "1.0.0",
      "data": {
        "state": state,
        "location": {
          "uuid": data.location.uuid
        }
      }
    })
    // Transition Appointment Response
    yield put(transitionAppointmentResponse({ data: response.data.data }))
  } catch (error) {
    // Transition Appointment Error
    yield put(transitionAppointmentError(error))
  }
}

// Export
export const TransitionAppointmentSaga = [
  takeLatest(TRANSITION_APPOINTMENT_REQUEST, handleTransitionAppointment)
]