import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';

import App from './App';
// Additional Dependencies
import RootReducer from './reducer/index';
import reportWebVitals from './reportWebVitals';
import RootSaga from './saga/index';

// Define Environment
const env = process.env.NODE_ENV

// Optimized Function to Get URI Path Parameter
function getURIPathParam(id, type) {
  const err = console.error;
  const uuidv4Regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  const segment = (window.location.pathname.split("/")[id] || "").split("#")[0];
  return (typeof id !== "number" || id < 1 || (type && typeof type !== "string")) ? (err("Invalid Parameter"), null)
    : !segment ? (err("Path Segment Does Not Exist"), null)
    : (type === "uuidv4" && !uuidv4Regex.test(segment)) ? (err("Invalid UUIDv4 Format"), null)
    : segment;
};

// Optimized Function to Get URL Query Parameter
function getURLQueryParam(name) {
  return name ? new URLSearchParams(window.location.search).get(name) : null;
}; getURLQueryParam();

// Optimized Function to Get Manifest
function getManifest() {
  const location_uuid = getURIPathParam(1, "uuidv4");
  return location_uuid ? `pwa/${location_uuid}/manifest.json` : null;
};

// Optimized Function to Load Manifest
function loadManifest(filename) {
  document.head.appendChild(Object.assign(document.createElement("link"), {
    type: "application/manifest+json",
    rel: "manifest",
    href: `${process.env.PUBLIC_URL}/${filename}`,
  }));
}; loadManifest(getManifest()); // Load Manifest

// Define Middleware
const sagaMiddleware = createSagaMiddleware()
const composeMiddleware = () => {
  let middleware = [applyMiddleware(sagaMiddleware)]
  if (env === "development" && window.__REDUX_DEVTOOLS_EXTENSION__) {
    middleware.push(window.__REDUX_DEVTOOLS_EXTENSION__())
  }
  return compose(...middleware)
}

// Create Redux Store
const store = createStore(RootReducer, composeMiddleware())

// Run Saga Middleware
sagaMiddleware.run(RootSaga)

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
