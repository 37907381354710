import React from 'react'

class Key extends React.Component {

  handleOnClick = () => {
      this.props.updateValue(this.props.letter)
  }

  render() {
    return (
      <div className="bg-white w-16 h-20 rounded-lg relative cursor-pointer" onClick={this.handleOnClick}>
        <div className="text-blue-950 absolute right-3 bottom-2 font-bold text-lg">
          {this.props.letter}
        </div>
      </div>
    )
  }
}

export default Key
