// Create Appointments Request
export const CREATE_APPOINTMENTS_REQUEST = 'CREATE_APPOINTMENTS_REQUEST'
export function createAppointmentsRequest() {
  return { type: CREATE_APPOINTMENTS_REQUEST }
}

// Create Appointments Response
export const CREATE_APPOINTMENTS_RESPONSE = 'CREATE_APPOINTMENTS_RESPONSE'
export function createAppointmentsResponse(payload) {
  return { type: CREATE_APPOINTMENTS_RESPONSE, payload }
}

// Create Appointments Error
export const CREATE_APPOINTMENTS_ERROR = 'CREATE_APPOINTMENTS_ERROR'
export function createAppointmentsError(data) {
  return { type: CREATE_APPOINTMENTS_ERROR, data }
}

export const SET_APPOINTMENT_VALUES = 'SET_APPOINTMENT_VALUES'
export function setAppointmentValues(data) {
  return { type: SET_APPOINTMENT_VALUES, data }
}