// Package Dependencies
// Additional Dependencies
import {
  CREATE_APPOINTMENTS_REQUEST,
  CREATE_APPOINTMENTS_RESPONSE,
  SET_APPOINTMENT_VALUES,
} from '../../action/Appointments/Create';

// Define Initial State
const INITIAL_STATE = {
  busy: false,
  processing: false,
  response: false,
  error: false,
  values: {
    time: '00:00:00'
  }
}

// Appointments Reducer
function CreateAppointmentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_APPOINTMENTS_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Appointments Response
    case CREATE_APPOINTMENTS_RESPONSE:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
      }
    case SET_APPOINTMENT_VALUES:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.data
        }
      }
    default:
      return state
  }
}

// Export
export default CreateAppointmentReducer
