import React from "react";
import Logo from "../../assets/img/fast-pass-logo.jpg";

export const Header = () => {

  const FSLogo = (e) => {
  	return (
        <img src={Logo} alt="" className="w-36"/>
      );
  }

  return (
    <div className="bg-white w-full">
      <div className="my-4 mx-4 flex flex-col">
        <div className="flex-grow">
          <FSLogo />
        </div>
      </div>
    </div>
  );
};
 export default Header;
