// Package Dependencies
import { all } from 'redux-saga/effects'
import { AppointmentsSaga } from './Appointments/Appointments'
import { TransitionAppointmentSaga } from './Appointments/Transition'
import { CreateAppointmentsSaga } from './Appointments/Create'
// Export
export default function* RootSaga() {
  yield all([
    ...AppointmentsSaga,
    ...TransitionAppointmentSaga,
    ...CreateAppointmentsSaga
  ])
}
