import '../../App.css';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import Button from '@alliancesafetycouncil/asc-button';

import {
  createAppointmentsRequest,
  setAppointmentValues,
} from '../../action/Appointments/Create';
import Time from '../../components/Calendar/Time';
import Header from '../../components/Header/Header';

// const days = [
//   { day: "Thu", date: 26, month: "May", value: '2022-05-26', selected: false },
//   { day: "Fri", date: 27, month: "May", value: '2022-05-27', selected: false },
//   { day: "Sat", date: 28, month: "May", value: '2022-05-28', selected: false },
//   { day: "Sun", date: 29, month: "May", value: '2022-05-29', selected: false },
//   { day: "Mon", date: 30, month: "May", value: '2022-05-30', selected: false },
//   { day: "Tue", date: 31, month: "May", value: '2022-05-31', selected: false },
//   { day: "Wed", date: 1, month: "June", value: '2022-06-01', selected: false }
// ]

const time_slots = [
  { time: "Now", value: '00:00:00', disabled: false, selected: true },
  { time: "8:00 AM", value: '08:00:00', disabled: false, selected: false },
  { time: "8:15 AM", value: '08:15:00', disabled: false, selected: false },
  { time: "8:30 AM", value: '08:30:00', disabled: false, selected: false },
  { time: "8:45 AM", value: '08:45:00', disabled: false, selected: false },
  { time: "9:00 AM", value: '09:00:00', disabled: false, selected: false },
  { time: "9:15 AM", value: '09:15:00', disabled: false, selected: false },
  { time: "9:30 AM", value: '09:30:00', disabled: false, selected: false },
  { time: "9:45 AM", value: '09:45:00', disabled: false, selected: false },
  { time: "10:00 AM", value: '10:00:00', disabled: false, selected: false },
  { time: "10:15 AM", value: '10:15:00', disabled: false, selected: false },
  { time: "10:30 AM", value: '10:30:00', disabled: false, selected: false },
  { time: "10:45 AM", value: '10:45:00', disabled: false, selected: false },
  { time: "11:00 AM", value: '11:00:00', disabled: false, selected: false },
  { time: "11:15 AM", value: '11:15:00', disabled: false, selected: false },
  { time: "11:30 AM", value: '11:30:00', disabled: false, selected: false },
  { time: "11:45 AM", value: '11:45:00', disabled: false, selected: false },
  { time: "12:00 PM", value: '12:00:00', disabled: false, selected: false },
  { time: "12:15 PM", value: '12:15:00', disabled: false, selected: false },
  { time: "12:30 PM", value: '12:30:00', disabled: false, selected: false },
  { time: "12:45 PM", value: '12:45:00', disabled: false, selected: false },
  { time: "1:00 PM", value: '13:00:00', disabled: false, selected: false },
  { time: "1:15 PM", value: '13:15:00', disabled: false, selected: false },
  { time: "1:30 PM", value: '13:30:00', disabled: false, selected: false },
  { time: "1:45 PM", value: '13:45:00', disabled: false, selected: false },
  { time: "2:00 PM", value: '14:00:00', disabled: false, selected: false },
  { time: "2:15 PM", value: '14:15:00', disabled: false, selected: false },
  { time: "2:30 PM", value: '14:30:00', disabled: false, selected: false },
  { time: "2:45 PM", value: '14:45:00', disabled: false, selected: false },
  { time: "3:00 PM", value: '15:00:00', disabled: false, selected: false },
]

export const DateTime = (props) => {
  // const [date, setDate] = useState(days)
  const [times, setTime] = useState(time_slots)
  const response = useSelector((state) => state.createAppointmentReducer.response)
  const values = useSelector((state) => state.createAppointmentReducer.values)
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  const validate = () => {
    let valid = true

    if (!values.time) {
      valid = false
      setError(true)
    }

    return valid
  }
  const handleChange = (key, value) => {
    setError(false)
    dispatch(setAppointmentValues({ [key]: value }))
  }
  const handleSubmit = () => {
    const valid = validate()

    if (valid) {
      dispatch(createAppointmentsRequest())
    }
  }

  //useIdleTimeout(() => window.location.href = "/", 1000 * 30)

  useEffect(() => {
    if (response) {
      props.history.push("/confirmation")
    }
  }, [response])

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <div style={{ display: 'none' }} className="flex flex-col h-screen">
      {/* Main Header wrapper */}
      <Header />
      {/* Main Content Area */}
      <div className="flex flex-col h-full">
        <div className="flex flex-col bg-blue-900">
          <div className="p-6">
            <div className="text-3xl text-white">
              Schedule a time, skip the line!
            </div>
            <div className="text-lg font-light text-white">
              Simply fill out the form below and select a time that works for you.
            </div>
          </div>
        </div>

        <div className="mx-16 mt-10">
          <form>

            {/* <div className="flex flex-row items-center mx-6">
              <div className="left arrow">
                <Button type="tertiary" size="md"><i class="far fa-angle-left text-3xl"></i></Button>
              </div>

              <div className="flex grid flex-grow grid-cols-7 mx-3">
                {date.map((d, index) => (
                  <Calendar
                    key={d.date}
                    day={d.day}
                    date={d.date}
                    month={d.month}
                    selected={d.selected}
                    onClick={() => {
                      setDate(date.map((day, i) => {
                        if (i === index) {
                          handleChange('date', d.value)
                          return {
                            ...day,
                            selected: true
                          }
                        } else {
                          return {
                            ...day,
                            selected: false
                          }
                        }
                      }))
                    }}
                  />
                ))}
              </div>

              <div className="right arrow">
                <Button type="tertiary" size="md"><i class="far fa-angle-right text-3xl"></i></Button>
              </div>
            </div>
            */}

            <div className={`mx-6 mb-10 text-2xl text-center ${error ? 'check-in-error' : ''}`}>
              Appointment time Today:
            </div>

            <div className="grid grid-cols-5 gap-4">
              {times.map((t, index) => (
                <div className="first:col-span-5" key={t.time}>
                  <Time
                    disabled={t.disabled}
                    time={t.time}
                    selected={t.selected}
                    onClick={() => {
                      setTime(times.map((x, i) => {
                        if (i === index) {
                          handleChange('time', t.value)
                          return {
                            ...x,
                            selected: true
                          }
                        } else {
                          return {
                            ...x,
                            selected: false
                          }
                        }
                      }))
                    }}
                  />
                </div>
              ))}
            </div>

            <div className="my-10 border-b border-blue-300"></div>

            <div className="flex justify-center">
              <Button type="save" size="md" onClick={handleSubmit}><span className="mx-8">Continue</span></Button>
            </div>

          </form>
        </div>
      </div>
      <div className="m-8" style={{ paddingLeft: "100px" }}>
        <Button type="secondary" size="md" onClick={() => props.history.push("/appointment")}><span className="mx-3">Back</span></Button>
      </div>
    </div>
  );
};

export default DateTime;
