import React, { useState } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import Button from '@alliancesafetycouncil/asc-button';

import { setAppointmentValues } from '../../action/Appointments/Create';
import {
  CheckBoxes,
  Input,
  PhoneInput,
} from '../../components/Form/Input';
import Header from '../../components/Header/Header';

export const Appointment = (props) => {

  const appointmentValues = useSelector(state => state.createAppointmentReducer.values)
  const [error, setError] = useState({})

  const validate = () => {
    let valid = true
    let errors = {
      firstName: false,
      lastName: false,
      mobile: false
    }

    if (!appointmentValues.firstName) {
      errors.firstName = true
      valid = false
    }

    if (!appointmentValues.lastName) {
      errors.lastName = true
      valid = false
    }

    if (!appointmentValues.mobile) {
      errors.mobile = true
      valid = false
    }

    setError(errors)
    return valid
  }

  const dispatch = useDispatch()
  const handleChange = (e) => {
    setError({})
    dispatch(setAppointmentValues({ [e.currentTarget.name]: e.currentTarget.value }))
  }
  const handleContinue = () => {
    const valid = validate()

    if (valid) {
      props.history.push("/date-time")
    }
  }

  //useIdleTimeout(() => window.location.href = "/", 1000 * 30)

  return (
    <div className="flex flex-col h-screen">
      {/* Main Header wrapper */}
      <Header />
      {/* Main Content Area */}
      <div className="flex flex-col h-full">
        <div className="flex flex-col bg-blue-900">
          <div className="p-6">
            <div className="text-3xl text-white">
              Schedule a time, skip the line!
            </div>
            <div className="text-lg font-light text-white">
              Simply fill out the form below and select a day and time that
              works for you.
            </div>
          </div>
        </div>

        <div className="mx-16 my-10">
          <form>
            <div className="grid grid-cols-2 gap-6">
              <div>
                <Input
                  label="First Name"
                  type="text"
                  name="firstName"
                  id="first-name"
                  error={error.firstName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <Input
                  label="Last Name"
                  type="text"
                  name="lastName"
                  id="last-name"
                  error={error.lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-2"></div>
              <div>
                <Input label="Email" type="text" name="email" id="email" onChange={handleChange} />
              </div>
              <div>
                <PhoneInput
                  label="Mobile Number"
                  type="text"
                  name="mobile"
                  id="phone"
                  error={error.mobile}
                  bottomLabel="Please Note: If you submit your mobile phone number, we’ll send a reminder about your appointment via SMS text."
                  onChange={handleChange}
                />
              </div>
              <div>
                <div className="block mb-4 font-medium text-gray-700">
                  Select the service(s) needed:
                </div>
                <div className="mb-3">
                  <CheckBoxes
                    key="cancel_plate"
                    type="checkbox"
                    name="service"
                    id="cancel_plate"
                    value={[] = ["cancel_plate"]}
                    label="Cancel Plate"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <CheckBoxes
                    key="drivers_license"
                    type="checkbox"
                    name="service"
                    id="drivers_license"
                    value={[] = ["drivers_license"]}
                    label="Drivers License"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <CheckBoxes
                    key="duplicate_title"
                    type="checkbox"
                    name="service"
                    id="duplicate_title"
                    value={[] = ["duplicate_title"]}
                    label="Duplicate Title"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <CheckBoxes
                    key="notary"
                    type="checkbox"
                    name="service"
                    id="notary"
                    value={[] = ["notary"]}
                    label="Notary"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <CheckBoxes
                    key="register_my_vehicle"
                    type="checkbox"
                    name="service"
                    id="register_my_vehicle"
                    value={[] = ["register_my_vehicle"]}
                    label="Register My Vehicle"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <CheckBoxes
                    key="renew_registration"
                    type="checkbox"
                    name="service"
                    id="renew_registration"
                    value={[] = ["renew_registration"]}
                    label="Renew Registration"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <CheckBoxes
                    key="other_services"
                    type="checkbox"
                    name="service"
                    id="other_services"
                    value={[] = ["other_services"]}
                    label="Other Services"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="my-10 border-b border-blue-300"></div>

            <div className="flex justify-center">
              <Button type="save" size="md" onClick={handleContinue}><span className="mx-8">Continue</span></Button>
            </div>

          </form>
        </div>
      </div>
      <div className="m-8" style={{ paddingLeft: "100px" }}>
        <Button type="secondary" size="md" onClick={() => props.history.push("/")}><span className="mx-3">Back</span></Button>
      </div>
    </div>
  );
};

export default Appointment;
