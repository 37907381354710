import React from 'react';

import { Link } from 'react-router-dom';

import Header from '../../components/Header/Header';

export const Decision = () => {
  return (
    <div className="flex flex-col h-screen">
      {/* Main Header wrapper */}
      <Header />
      {/* Main Content Area */}
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-center h-full bg-blue-900">
          <div className="w-2/3 text-center">
            <div className="text-5xl font-light text-white">
              Have an Appointment?
            </div>
            <div>
              <Link to="/check-in">
                <div
                  className="flex items-center justify-center h-20 mt-12 text-4xl text-blue-900 bg-white rounded-xl"
                >
                  Confirm Appointment
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="relative h-full bg-white">
          <div className="absolute w-full -top-16">
            <div
              className="flex items-center justify-center w-32 h-32 mx-auto text-4xl font-light text-blue-900 bg-white rounded-full ">
                <div>
                  Or
                </div>
              </div>
            </div>

          <div className="flex items-center justify-center h-full">
            <div className="w-2/3 text-center">
              <div className="text-5xl font-light text-blue-900">
                No Appointment?
              </div>
              <div className="text-white">
                <Link to="/appointment">
                  <div
                    className="flex items-center justify-center h-20 mt-12 text-4xl text-white bg-blue-900 rounded-xl"
                  >
                    Make an Appointment
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Decision;
