import React from 'react';

import { connect } from 'react-redux';

import {
  retrieveAppointmentsRequest,
} from '../../action/Appointments/Appointments';
import Confirmed from '../../components/Confirmation/Confirmed';
import Header from '../../components/Header/Header';

class Confirmation extends React.Component {
  componentDidMount() {
    this.props.FetchAppointments({ mobile: this.props.value });

    // Optimized Function to Get URI Path Parameter
    function getURIPathParam(id, type) {
      const err = console.error;
      const uuidv4Regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
      const segment = (window.location.pathname.split("/")[id] || "").split("#")[0];
      return (typeof id !== "number" || id < 1 || (type && typeof type !== "string")) ? (err("Invalid Parameter"), null)
        : !segment ? (err("Path Segment Does Not Exist"), null)
        : (type === "uuidv4" && !uuidv4Regex.test(segment)) ? (err("Invalid UUIDv4 Format"), null)
        : segment;
    };

    window.setTimeout(function () {
      window.location.href = `/${getURIPathParam(1, "uuidv4")}`;
    }, 7000);
  }

  render() {
    const { done } = this.props;

    return (
      <div className="flex flex-col h-screen">
        {/* Main Header wrapper */}
        <Header />
        {/* Main Content Area */}
        <div className="flex-auto w-2/3 mx-auto mt-28 row">
          {done && <Confirmed />}
          {/* <NoMatch />  Comment Confirmed above and uncomment this to toggle between confirmation and no match. */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.appointmentReducer.busy,
    done: !state.appointmentReducer.busy && !state.appointmentsReducer.busy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    FetchAppointments: (uuid) => dispatch(retrieveAppointmentsRequest(uuid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
