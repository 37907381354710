import { DateTime } from 'luxon';
// Package Dependencies
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

// Action Dependencies
import {
  CREATE_APPOINTMENTS_REQUEST,
  createAppointmentsError,
  createAppointmentsResponse,
} from '../../action/Appointments/Create';
// Additional Dependencies
import axios from '../../utility/Axios';

// Optimized Function to Get URI Path Parameter
function getURIPathParam(id, type) {
  const err = console.error;
  const uuidv4Regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  const segment = (window.location.pathname.split("/")[id] || "").split("#")[0];
  return (typeof id !== "number" || id < 1 || (type && typeof type !== "string")) ? (err("Invalid Parameter"), null)
    : !segment ? (err("Path Segment Does Not Exist"), null)
    : (type === "uuidv4" && !uuidv4Regex.test(segment)) ? (err("Invalid UUIDv4 Format"), null)
    : segment;
};

// Function Handle Appointments
function* handleCreateAppointments() {
  try {
    const location_uuid = getURIPathParam(1, "uuidv4");
    const date = DateTime.now()
      .setZone("UTC")
      .toFormat("yyyy-MM-dd")
      .toLocaleString(DateTime.DATE_SHORT);
    const values = yield select((state) => state.createAppointmentReducer.values);
    let appointment_time;

    // Conditional Handling: On Demand Appointment Time
    if (values.time === "00:00:00") {
      // Get Current Time
      const current_time = DateTime.now()
        .setZone("US/Central")
        .toLocaleString(DateTime.TIME_24_SIMPLE);
      const current_minute = DateTime.now().setZone("US/Central").toFormat("mm");
      // Calculate Appointment Time by Rounding Up to the Closest 15 Minute Slot
      appointment_time = DateTime.now()
        .plus({ minutes: 15 - (current_minute % 15) })
        .setZone("US/Central")
        .toFormat("yyyy-MM-dd HH:mm:00");
    } else {
      // Conditional Handling: Selected Appointment Time
      appointment_time = `${date} ${values.time}`;
    }

    // Conditional Handling: Appointment Type
    // Set Appointment Type to 'general' if Multiple Services are Selected

    // Create Appointment Request
    const request = yield call(axios.post_request, `/appointment`, {
      version: "1.0.0",
      data: {
        location: {
          uuid: location_uuid,
        },
        contact: {
          name: {
            first: values.firstName,
            last: values.lastName,
          },
          mobile: `1${values.mobile.replace(/\D/g, "")}`,
          email: values.email,
        },
        type: values.service ? values.service : 'general',
        source: "walk_in",
        date_time: appointment_time,
        locale: {
          timezone: "us_central",
        },
      },
    });

    // Conditional Handling: On Demand Appointment Check In
    if (values.time === "00:00:00") {
      // Get Appointment UUID and State
      const appointment_uuid = request.data.data.appointment.uuid;
      const appointment_state = request.data.data.appointment.state;
      // Conditional Handling: Only for Scheduled Appointments
      if (appointment_state === "scheduled") {
        const response = yield call(
          axios.patch_request,
          `/appointment/${appointment_uuid}/transition`,
          {
            version: "1.0.0",
            data: {
              state: "queued",
              location: {
                uuid: getURIPathParam(1, "uuidv4"),
              },
            },
          }
        );
      }
    }

    // Retrieve Appointments Response
    yield put(createAppointmentsResponse());
  } catch (error) {
    // Retrieve Appointments Error
    yield put(createAppointmentsError(error));
  }
}

// Export
export const CreateAppointmentsSaga = [
  takeLatest(CREATE_APPOINTMENTS_REQUEST, handleCreateAppointments),
];
