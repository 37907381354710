// Package Dependencies
// Additional Dependencies
import {
  TRANSITION_APPOINTMENT_REQUEST,
  TRANSITION_APPOINTMENT_RESPONSE,
  TRANSITION_APPOINTMENT_ERROR
} from '../../action/Appointments/Transition'

// Define Initial State
const INITIAL_STATE = {
  busy: false,
  processing: false,
  response: false,
  error: false,
}

// Appointment Reducer
function AppointmentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Transition Appointment Request
    case TRANSITION_APPOINTMENT_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Transition Appointment Response
    case TRANSITION_APPOINTMENT_RESPONSE:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
      }
    // Transition Appointment Error
    case TRANSITION_APPOINTMENT_ERROR:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: 'Transition Appointment Error'
      }
    default:
      return state
  }
}

// Export
export default AppointmentReducer