import React from "react";

export const BackSpace = ({label, onClick}) => {
  return (
    <div className="bg-white w-full h-20 rounded-lg relative cursor-pointer" onClick={onClick} >
      <span className="text-blue-950 absolute right-3 bottom-2 font-bold text-lg">{label}</span>
    </div>
  );
};
 export default BackSpace;
