import React from 'react'
import ReactDOM from 'react-dom'
import Key from './Key'
import BackSpace from './BackSpace'
import NumberFormat from 'react-number-format'
import "../../App.css"

export default class KeyPad extends React.Component {
  state = {
    letters: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
    value: '',
  }

  updateValue = letter => {
    const { value, setValue } = this.props

    setValue(value + letter)
  }

  deleteValue = () => {
    const { value, setValue } = this.props

    setValue(value.slice(0,-1))
  }

  createButtons = () => {
    const letters = this.state.letters
    return letters.map(letter => (
      <Key letter={letter} updateValue={this.updateValue} />
    ))
  }

  render() {
    const { value, error } = this.props;

    return (
      <div>
      <div className="flex-column w-full sm:w-80 m-auto">
        <label className="block text-gray-900 font-medium text-lg mb-2" for="phone">
          <span className="text-white">Mobile Number</span>
        </label>
        <NumberFormat
          value={value}
          format="(###) ###-####"
          className={`text-3xl font-medium text-blue-950 text-center appearance-none shadow border-0 h-10 rounded-lg w-full py-8 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${error ? 'check-in-error' : ''}`}
          placeholder="(xxx) xxx-xxxx"
          id="phoneInput"
        />
        </div>
        <div className="mt-16 flex m-auto justify-center">
        <div className="grid grid-cols-3 gap-4">
          {this.createButtons()}
          <div className="col-span-2"><BackSpace label="Backspace" onClick={() => this.deleteValue()}/></div>
        </div>
        </div>
      </div>
    )
  }
}

ReactDOM.render(<KeyPad />, document.getElementById('root'))
